import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "puppeteer-to-video",
  "author": "tzookb",
  "type": "post",
  "date": "2022-11-22T13:50:08.000Z",
  "url": "/puppeteer-to-video",
  "featuredImage": "./puppeteer.jpg",
  "desc": "how to manage yourself, and mange your managers",
  "categories": ["API", "DB modeling"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f80d0ab2df3c52773164acbcc0a9644a/79166/screenshot.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.00000000000001%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAABhklEQVQoz7XRO0/CUBQH8H5JTdxakUdpqSRuiA4oGEtbQEFBdjcHE0PiA3xEBBSkLRQQJ5mUwaCT29/ctkHwmWgcfrmnp6fnnNxS93vn6B+U0d8v/UEZD7kiXh4eQVUnA1Ady6gzoY/o0Of5d9TpJdSmghhU26DUmTCafgUNQbZJaMzKdk4ayX+N1GruFTxpXVCku/khHzXpnGjFgvQW/8QnQXWG8aT+Z0OdIy9F89TtItJQN4eMG9Zx325oFTV5EQa/amqaxDGjzUaHf7lhhY3j1J1E0bOGSzaGqlcZo3FRtOyBhj3QEKIgP3dAGtanQ9Dt+yKuvRIqbAwlNo4z9zqOXSmcuJND5LngSiHv2kDRk8CVV8ElF4fmjFgbGjMRdPwxdAQFbUFBR5BxI8joCpLpdlZC10bilk+BzifQ8MVx5U3hgt3ErnMbJXoZz+oNqNzkHI4cizik500HdPBbpOaIseSZAApMAHnHAnYm/LivGaDWEglkt7aQSad/ITOUTWfQu+vhFXqequuLiLMfAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "slide deck I wanted to save",
              "title": "slide deck I wanted to save",
              "src": "/static/f80d0ab2df3c52773164acbcc0a9644a/0a47e/screenshot.png",
              "srcSet": ["/static/f80d0ab2df3c52773164acbcc0a9644a/8a4e8/screenshot.png 150w", "/static/f80d0ab2df3c52773164acbcc0a9644a/5a46d/screenshot.png 300w", "/static/f80d0ab2df3c52773164acbcc0a9644a/0a47e/screenshot.png 600w", "/static/f80d0ab2df3c52773164acbcc0a9644a/1cfc2/screenshot.png 900w", "/static/f80d0ab2df3c52773164acbcc0a9644a/c1b63/screenshot.png 1200w", "/static/f80d0ab2df3c52773164acbcc0a9644a/79166/screenshot.png 2012w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`slide deck I wanted to save`}</figcaption>{`
  `}</figure></p>
    <p>{`How I extracted HTML slide decks into videos with Puppeteer and a small python script.`}</p>
    <p>{`Recently I took a course on how to improve product UX. The course was interesting as it actually showed real examples from the real world with products like Lyft, DoorDash, Airbnb, and more.
The course content was mostly in some cool slide deck, the slide was moving HTML elements as you go on. I wanted to save this deck so I could refer to it when needed. Export wasn’t available. My next option was to record the screen and go through all the decks manually, workable but annoying.
I decided I’ll create a script that will automate the extraction for me. I planned the script to do this:`}</p>
    <ul>
      <li parentName="ul">{`open the page`}</li>
      <li parentName="ul">{`screenshot the slide deck element`}</li>
      <li parentName="ul">{`Click the slide deck to move to the next slide`}</li>
      <li parentName="ul">{`repeat the two steps until the deck is done`}</li>
      <li parentName="ul">{`generate a video from all the screenshots above.`}</li>
    </ul>
    <h2>{`The Slide Deck Extractor script`}</h2>
    <ul>
      <li parentName="ul">{`open a new puppteer browser and page`}</li>
      <li parentName="ul">{`set screen size to be large enough to have sized screenshots`}</li>
      <li parentName="ul">{`set the proper cookies as the page is protected`}</li>
      <li parentName="ul">{`open the page and wait for all network calls to load`}</li>
      <li parentName="ul">{`find and wait for the iframe where the slide deck exist`}</li>
      <li parentName="ul">{`loop 200 times, wait, click picture`}</li>
      <li parentName="ul">{`I was lazy on finding when the deck ends`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import puppeteer from 'puppeteer';

function delay(time) {
  return new Promise(function(resolve) { 
      setTimeout(resolve, time)
  });
}
(async () => {
  const browser = await puppeteer.launch({
    headless: false,
    executablePath: '/Applications/Google\\ Chrome.app/Contents/MacOS/Google\\ Chrome'
  });

  const generate = async function(destPath, uri) {
    const page = await browser.newPage();
    
    await page.setViewport({
      width: 3000,
      height: 2000,
    });

    const cookies = ['session-cookies-here'];

    await page.setCookie(...cookies);

    await page.goto(uri, {
      waitUntil: 'networkidle2',
    });

    const selector = '.deck iframe';
    await page.waitForSelector(selector);
    const element = await page.$(selector);

    let count = 1;
    const frame = page.frames().find(frame => frame.url().startsWith('https://example.com/slides'));

    while (count <= 200) {
      await delay(2000);
      await element.screenshot({path: \`\${destPath}/p\${count}.png\`});
      await frame.click('.navigate-right');
      count++;
    }
  }

  await generate(folder, uri)
  await browser.close();                          
})();
`}</code></pre>
    <h2>{`The Video Generator`}</h2>
    <ul>
      <li parentName="ul">{`super simple script here`}</li>
      <li parentName="ul">{`get a folder, and video dest name`}</li>
      <li parentName="ul">{`read all the images in the folder`}</li>
      <li parentName="ul">{`sort them by the name (p1, p2, p3,..., p10, p11,...)`}</li>
      <li parentName="ul">{`concat all the images together with 2 second duration`}</li>
      <li parentName="ul">{`save the video`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import cv2
import functools
import numpy as np
import os

def genVideo(image_folder, video_file):
  image_size = (960, 540)
  each_image_duration = 2
  def cut(x):
      return x[1:][0:-4]
  def compare(x, y):
      return 1 if int(cut(x)) > int(cut(y)) else -1
      
  images = [img for img in os.listdir(image_folder) if img.endswith(".png")]

  images.sort(key=functools.cmp_to_key(compare))

  out = cv2.VideoWriter(video_file, cv2.VideoWriter_fourcc('m', 'p', '4', 'v'), 1.0, image_size)

  for filename in images:
      img = cv2.imread(os.path.join(image_folder, filename))
      for _ in range(each_image_duration):
          out.write(img)

  out.release()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      